export default class CaptchaManager {

    constructor() {
        this.recaptchaEnabled = !myApp.config.parameters.isOffice && !myApp.config.parameters.isDev;
        this.googleLibraryURL = 'https://www.google.com/recaptcha/api.js';
        this.recaptchaAction = "homepage";
    }

    captchaInitialize(element, action = "homepage") {
        if (!this.recaptchaEnabled) {
            return;
        }
        if (element.length == 0) {
            return;
        }

        this.recaptchaAction = action;
        const inputNameRecaptchaToken = "g-recaptcha-token";
        $(`input[name='${inputNameRecaptchaToken}']`).remove();
    }

    async executeCaptcha(form) {
        if (!this.recaptchaEnabled) {
            return;
        }
        const token = await this.generateRecaptchaToken();
        this.appendInput(form, token);
    }

    async generateRecaptchaToken() {
        await this.getRecaptcha();
        return await new Promise((resolve, reject) => {
            grecaptcha.ready(() => {
                grecaptcha.execute(myApp.config.parameters.publicCaptchaKey, {action: this.recaptchaAction})
                    .then(resolve)
                    .catch(reject);
            });
        });
    }

    appendInput(form, token) {
        const inputNameRecaptchaToken = "g-recaptcha-token";
        const currentInput = form.querySelector(`[name="${inputNameRecaptchaToken}"]`);
        if (currentInput) currentInput.remove();

        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = inputNameRecaptchaToken;
        input.value = token;
        form.append(input);
    }

    async getRecaptcha() {
        return new Promise((resolve, reject) => {
            if (typeof grecaptcha !== "undefined") {
                return resolve();
            }

            $.getScript(`${this.googleLibraryURL}?render=${myApp.config.parameters.publicCaptchaKey}`)
                .done(() => resolve())
                .fail((error) => reject(error));
        });
    }
}
